<template>
    <div class="row">
        <div class="col-md-12">
        <!-- Custom Tabs -->
            <div class="row">
                <div class="col-md-12">
                    <div class="box box-info">
                        <div class="box-header with-border">
                              <h3 class="box-title">Panel List</h3>
                           </div>
                        <div class="box-body table-responsive">
                        <div class="notdatatable form-inline mbottom-15">
                            <div class="table-controls table-columns">
                                <div class="btn-group"><button ng-if="viewer.CanExport()" class="btn btn-default" type="button" ng-click="viewer.ExportExcel()"><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div>
                            </div>
                            <div class="table-controls table-pagesize table-columns">
                                <label>
                                    Show 
                                    <select class="form-control input-xs">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                    </select>
                                    entries 
                                </label>
                            </div>
                            
                            <div class="table-controls table-filter">Filter Alerts <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
                        </div>
                        <table class="table table-striped table-hover">
                            <thead>
                            <tr id="row1">
                                <th class="no-sort"  name="name" width="30"></th>
                                <th class="text-capitalize sorting">
                                    Panel Name
                                </th>
                                <th class="text-capitalize sorting">
                                     Type
                                </th>
                                <th class="text-capitalize sorting">
                                    Condition
                                </th>
                                <th class="text-capitalize sorting">
                                    Tag
                                </th>
                                <th class="text-capitalize sorting">
                                 
                                    Topic
                                </th>
                                <th class="text-capitalize sorting">
                                 Submit URL
                                </th>
                                <th class="text-capitalize sorting">
                                 Menu Label
                                </th>
                                 <th class="text-capitalize sorting">
                                 Enable/Disable
                                </th>
                               
                            </tr>
                            </thead>
                            <tbody>
                                <!-- @click="showMessageDetails('','row2'); " v-bind:class = "showDetails.active_el == 'row2' ?'row-active':''"-->
                            <tr id="row2">
                                <td name="name" class="col-icon-edit">
                                 <div class="d-flex"> 
                                    <button class="btn btn-xs btn-link text-default" v-on:click="paneleditrow1 = !paneleditrow1;" v-show="!paneleditrow1">
                                       <i class="fa fa-pencil"></i>
                                    </button>
                                 </div>
                                 </td>
                                <td>
                                    <span v-if="!paneleditrow1">
                                	    Initial Request Form
                                    </span>
                                    <span v-if="paneleditrow1">
                                	   <input class="form-control" value="Initial Request Form">
                                    </span>
                                </td>
                                <td>
                                   Customer Submit
                                </td>
                                <td>
                                Tag and Topic
                                </td>
                                <td>
                                    <span v-if="!paneleditrow1">
                                        retro
                                    </span>
                                    <span v-if="paneleditrow1">
                                	   <input class="form-control" value="retro">
                                    </span>
                               </td>
                                <td>
                                    <span v-if="!paneleditrow1">
                                        All topic
                                    </span>
                                    <span v-if="paneleditrow1">
                                        <Multiselect
                                                v-model="value"
                                                placeholder="Select topic"
                                                label="name"
                                                class="small-input"
                                                :groups="true"
                                                :searchable="true"
                                                :options="[
                                                    {
                                                    label: 'Customer Success',
                                                    options:  [
                                                        { value: 'eshop all topics', name: 'e shop -- All Topics',level:'1'},
                                                        { value: '_Feedback', name: '_Feedback',level:'2'},
                                                        { value: '_Newsletter Subscription', name: '_Newsletter Subscription',level:'2'},
                                                        { value: '_Spam', name: '_Spam',level:'2'},
                                                        { value: 'Undeliverd', name: 'Undeliverd',level:'2'},
                                                        { value: 'Account Questions', name: 'Account Questions',level:'1'},
                                                        { value: 'Return', name: 'Return',level:'2'},
                                                        { value: 'Return', name: 'Return',level:'2'},
                                                        { value: 'Cancel', name: 'Cancel',level:'2'},
                                                    ],
                                                    }
                                                ]"
                                                >
                                                <template v-slot:option="{ option }">
                                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>
                                    </span>
                                </td>
                               
                                <td>
                                s/regro/submit
                                </td>
                                <td>
                                    <span v-if="!paneleditrow1">
                                        My Form
                                    </span>
                                    <span v-if="paneleditrow1">
                                	   <input class="form-control" value=" My Form">
                                    </span>
                                </td>
                                <td>
                                <Toggle/>
                                </td>
                                <td>
                                  
                                  <span v-if="!paneleditrow1">
                                    Preview
                                  </span>
                                  <span v-if="paneleditrow1"> 
                                    <button class="btn btn-xs btn-primary" v-show="paneleditrow1">
                                             Save
                                    </button>
                                    <button class="btn btn-xs btn-link" v-show="paneleditrow1" v-on:click="paneleditrow1 = !paneleditrow1">
                                             Cancel
                                    </button>
                                   </span>
                                 </td>   
                              
                            </tr>

                            
                            </tbody>
                        </table>
                        <div id="Main_MyDashboard_Recent_TableInfo">
                            <div class="notdatatable table-info">Showing 1 to 2 of 2 entries</div>
                            <div class="dataTables_paginate notdatatable table-pagination text-end">
                                <ul class="pagination">
                                    <li><a href="">Previous</a></li>
                                    <li class="active"><a href="">1</a></li>
                                    <li><a href="">Next</a></li>
                                </ul>
                            </div>
                            </div>
                        <!-- Data loader start
                        <div class="data-loader">
                            <i class="fa fa-spinner fa-spin fa-fw"></i>
                        </div>  -->
                        <!-- Data loader end-->
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                </div>
            </div>
        </div>
        <!-- /.col -->
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <button  class="btn btn-primary" v-on:click="showNewAlert('customer-submit')">New Customer Submit</button>
          <button  class="btn btn-primary" v-on:click="showNewAlert('agent-submit')">New Agent Submit</button>
          <button  class="btn btn-primary" v-on:click="showNewAlert('answer-panel')">New Answer Panel</button>
          <button class="btn btn-danger" v-on:click="showDeleteAlert()">Delete Panel</button>
        </div>
      </div>
    </div>

    <div class="expandedrowcontent box-bg-color" v-if="showDetails.showNew">
        <div class="box box-info with-border" v-if="showDetails.displayformType == 'customer-submit'">
            <div class="box-body table-responsive">
                <div class="box-header with-border">
                    <h3 class="box-title text-right  mright-15 ">Initial Request Form</h3>
                    <span> <label class="control-label text-left"> 
                                <Toggle/> Enable Panel
                            </label>
                    </span>
                    <span aria-hidden="true" class="fa fa-angle-right pull-right mleft-15 chevron-icon expanded" v-bind:class = "segment1?'':'chevron-expand-indicator_expanded'"  v-on:click="toggleSegmentCollapsedState('1')"></span>
                    <span class="pull-right"><button class="btn btn-sm btn-primary">Preivew</button></span>
                </div>
                <div class="box-body">
                    <div class="row">
                       <div class="col-md-4">
                           <div class="form-group">
                              <label class="control-label text-left">Form URL</label>
                              <div class="d-flex">
                                 <span class="text-bold mt-2 mright-5">s/retrofit/submit/ </span>
                                 <input class="form-control small-input" type="text" id="Main_Workflow_Details_Label">
                              </div>
                              <div>
                                 <span>URL must be unique for this workflow. <br>
                                    This submit form is accessible to anonymous users at the url: <strong>s/retrofit/submit</strong>
                                </span>
                              </div>
                           </div>
                        </div>
                       <div class="col-md-4">   
                           <div class="form-group">
                              <label class="control-label text-left">Frame Type</label>
                             
                              <div>
                                 <span>  If needed, the submit form can be used in a frame either in other forms in the same tenant or from any external website.</span>
                              </div>
                              <div>
                                 <span> <strong>As best practice frame support should be disabled unless required.</strong>
                                
                                </span>
                                <div><label class="control-label text-left margin-r-5 text-red">Frame Type</label><a class="btn btn-xs margin-r-5 btn-default">Not Allowed</a><a class="btn btn-xs margin-r-5 btn-default">Same Site</a><a class="btn btn-xs margin-r-5 btn-default">Any Site</a></div>
                              </div>
                           </div>
                        </div>
                        
                        <div class="col-md-4">
                            <span>
                                <label class="control-label text-left margin-r-5">Success Message Type</label>
                                <a class="btn btn-xs margin-r-5 active btn-primary" id="standard">Standard</a>
                                <a class="btn btn-xs margin-r-5 btn-default">Custom HTML</a>
                                <a class="btn btn-xs margin-r-5 btn-default"><i class="fa fa-pencil"></i></a>
                            </span>
                        </div>
                    </div>

                    <div class="row">
                      
                        <div class="nested-panel-heading skill-heading"><h3 class="nested-title">Advanced Settings</h3>
                        <p class="mt-2 mb-0">Use these settings to modify the default settings of your form. Use caution when adding these values as they can potentially break your form.</p>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="control-label text-left">Custom Head HTML</label>
                                            <div class="">
                                                <textarea class="form-control" type="text" ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="control-label text-left">Body Class</label>
                                            <div class="">
                                                <textarea class="form-control" type="text" ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="control-label text-left">Custom CSS</label>
                                            <div class="">
                                                <textarea class="form-control" type="text" ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                 
                             </div>
                        </div>
                   
                    </div>
                </div>
            </div>
        </div>
        <div class="box box-info with-border" v-if=" showDetails.displayformType == 'agent-submit'">
            <div class="box-body table-responsive">
                <div class="box-header with-border">
                    <h3 class="box-title text-right  mright-15">Initial Request By Agent</h3>
                    <span> <label class="control-label text-left"> 
                                <Toggle/> Enable Panel
                            </label>
                    </span>
                    
                    <span aria-hidden="true" class="fa fa-angle-right pull-right mleft-15 chevron-icon expanded" v-bind:class = "segment1?'':'chevron-expand-indicator_expanded'"  v-on:click="toggleSegmentCollapsedState('1')"></span>
                    <span class="pull-right"><button class="btn btn-sm btn-primary">Preivew</button></span>
                </div>
                <div class="box-body"  v-bind:class = "segment1?'in collapse':''">
                    <div class="row">
                       <div class="col-md-6">
                           <div class="form-group">
                              <label class="control-label text-left">Form Name in URL</label>
                              <div class="d-flex">
                                 <span class="text-bold mt-2 mright-5">app/retrofit/submit/agentsubmit</span>
                                 <input class="form-control small-input" type="text" id="Main_Workflow_Details_Label">
                              </div>
                              <div>
                                 <span>URL must be unique for this workflow. <br>
                                    This submit form is accessible to logged in agents users at the url:<br> 
                                    app/retrofit/submit/agentsubmit
                                </span>
                              </div>
                           </div>
                    </div>
                    <div class="col-md-6">
                            <div class="form-group">
                              <label class="control-label text-left">Agent Menu Link Name</label>
                             
                              <div class="">
                                 <input class="form-control" type="text">
                              </div>
                              <div>
                                 <span>This text is shown as the link to the submit form in the left side menu. 
                                </span>
                              
                           </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        </div>
        <div class="box box-info with-border" v-if=" showDetails.displayformType == 'answer-panel'">
            <div class="box-body table-responsive">
                <div class="box-header with-border">
                    <h3 class="box-title text-right  mright-15">Initial Request By Agent</h3>
                    <span> <label class="control-label text-left"> 
                                <Toggle/> Enable Panel
                            </label>
                    </span>
                   
                    <span aria-hidden="true" class="fa fa-angle-right pull-right mleft-15 chevron-icon expanded" v-bind:class = "segment1?'':'chevron-expand-indicator_expanded'"  v-on:click="toggleSegmentCollapsedState('1')"></span>
                    <span class="pull-right"><button class="btn btn-sm btn-primary">Preivew</button></span>
                </div>
                <div class="box-body"  v-bind:class = "segment1?'in collapse':''">
                    <div class="row">
                       <div class="col-md-6">
                          
                           <div class="form-group">
                              <label class="control-label text-left">Panel Condition</label>
                              <div><label class="control-label text-left margin-r-5 text-red">Condition</label><a class="btn btn-xs margin-r-5 btn-default">Tag</a><a class="btn btn-xs margin-r-5 btn-default">Topic</a><a class="btn btn-xs margin-r-5 btn-default">Tag & Topic</a></div>
                              <div>
                                 <span>Select condition for when this panel is used
                                </span>
                              </div>
                           </div>
                    </div>
                    <div class="col-md-6">
                            <div class="form-group">
                              <label class="control-label text-left">Topic</label>
                             
                              <div class="">
                                <Multiselect
                                    v-model="value"
                                    placeholder="Select topic"
                                    label="name"
                                    :groups="true"
                                    :searchable="true"
                                    :options="[
                                        {
                                        label: 'Customer Success',
                                        options:  [
                                            { value: 'eshop all topics', name: 'e shop -- All Topics',level:'1'},
                                            { value: '_Feedback', name: '_Feedback',level:'2'},
                                            { value: '_Newsletter Subscription', name: '_Newsletter Subscription',level:'2'},
                                            { value: '_Spam', name: '_Spam',level:'2'},
                                            { value: 'Undeliverd', name: 'Undeliverd',level:'2'},
                                            { value: 'Account Questions', name: 'Account Questions',level:'1'},
                                            { value: 'Return', name: 'Return',level:'2'},
                                            { value: 'Return', name: 'Return',level:'2'},
                                            { value: 'Cancel', name: 'Cancel',level:'2'},
                                        ],
                                        }
                                    ]"
                                    >
                                    <template v-slot:option="{ option }">
                                    <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                    </template>
                                </Multiselect>
                              </div>
                              <div>
                                 <span>This text is shown as the link to the submit form in the left side menu. 
                                </span>
                              
                           </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        </div>
        <div class="row mbottom-15">
                        <div class="col-md-4">
                            <div class="workflow-element-container" style="position:relative">
                            <div class="workflow-element-header">
                                    <span>Panel Elements</span>
                                   <!-- <span class="element-icon px-2"> <i class="fa  fa-th-large"></i></span>-->
                            </div>
                            <div class="workflow-element-body">
                                    <div class="workflow-container">
                                        <div class="nav-tabs-custom">
                                        <ul class="nav nav-tabs">
                                                <li class="nav-item"><a id="tab-element" class="nav-link active" data-bs-toggle="tab" data-bs-target="#workflow_tab_property_41" role="tab" aria-controls="formbody"  aria-selected="true"> <span>Input Type</span></a> </li>
                                                <li class="nav-item"><a id="tab-settings" class="nav-link" data-bs-toggle="tab" data-bs-target="#workflow_tab_property_42" role="tab" aria-controls="formbody"  aria-selected="true"><span>Settings </span></a> </li>
                                        </ul>
                                        <div class="tab-content">
                                                <div class="tab-pane active" id="workflow_tab_property_41">
                                                    <h4>Page Structure</h4>
                                                    <div class="element-item" draggable="true" id="html"   @dragstart="startDrag($event, 'html')">
                                                    <span class="element-item-icon"><img src="/img/workflow-element-icon/html.png" alt="HTML"></span>
                                                    <span>HTML</span>
                                                    </div>
                                                    <div class="element-item" draggable="true" id="buttons"  @dragstart="startDrag($event, 'buttons')">
                                                    <span class="element-item-icon"><img src="/img/workflow-element-icon/button.png" alt="Button"></span>
                                                    <span>Buttons</span>
                                                    </div>
                                                    <div class="element-item" draggable="true" @dragstart="startDrag($event, 'tabs')">
                                                    <span class="element-item-icon"><img src="/img/workflow-element-icon/tab-group.png" alt="Tab"></span>
                                                    <span>Tab Group</span>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <h4>Form Inputs</h4>
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon"><img src="/img/workflow-element-icon/upload.png" alt="Uploads"></span>
                                                    <span>Uploads</span>
                                                    </div>
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon"><img src="/img/workflow-element-icon/interaction.png" alt="Interaction Properties"></span>
                                                    <span>Interaction Properties</span>
                                                    </div>
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon"><img src="/img/workflow-element-icon/email.png" alt="html"></span>
                                                    <span>Email</span>
                                                    </div>
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon"><img src="/img/workflow-element-icon/contact.png" alt="Contact Properties"></span>
                                                    <span>Contact Properties</span>
                                                    </div>
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon"><img src="/img/workflow-element-icon/line.png" alt="Single Plain Text"></span>
                                                    <span>Single Plain Text</span>
                                                    </div>
                                                
                                                
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon"><img src="/img/workflow-element-icon/line.png" alt="html"></span>
                                                    <span>Multiline Plain Text</span>
                                                    </div>
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon"><img src="/img/workflow-element-icon/rich-text.png" alt="html"></span>
                                                    <span>Multiline Rich Text</span>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <h4>Answer Panels</h4>
                                                    <div class="element-item" draggable="true">
                                                            <span class="element-item-icon"  id="answer" >
                                                                <i class="fa fa-reply"></i>
                                                             </span>
                                                        
                                                        <span>Standard Answer</span>
                                                    </div> 
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa fa-envelope"></i>
                                                    </span>
                                                    <span>Standard Answer</span>
                                                    </div> 
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa fa-pencil-square-o"></i>
                                                    </span>
                                                    <span>Create Note</span>
                                                    </div>
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa fa-save"></i>
                                                    </span>
                                                    <span>Set Draft</span>
                                                    </div> 
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa icon-c icon-sen-leave-open"></i>
                                                    </span>
                                                    <span>Send Leave Open</span>
                                                    </div> 
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa icon-c icon-sen-leave-open"></i>
                                                    </span>
                                                    <span>Send Resolve</span>
                                                    </div> 

                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa fa-save"></i>
                                                    </span>
                                                    <span>Save Resolve</span>
                                                    </div> 

                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa icon-c icon-public-note"></i>
                                                    </span>
                                                    <span>Save Public Note</span>
                                                    </div> 

                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa icon-c icon-private-note"></i>
                                                    </span>
                                                    <span>Save Private Note</span>
                                                    </div> 

                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa fa-arrows-alt"></i>
                                                    </span>
                                                    <span>Change Topic</span>
                                                    </div>
                                                    
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa fa-user"></i>
                                                    </span>
                                                    <span>Assign To Agent</span>
                                                    </div>

                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa fa-user"></i>
                                                    </span>
                                                    <span>Unassign </span>
                                                    </div>
                                                    
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa fa-bell-o"></i>
                                                    </span>
                                                    <span>Send Notification </span>
                                                    </div>
    
                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa fa-align-justify"></i>
                                                    </span>
                                                    <span>Set Property</span>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <h4>Answer Panel Inputs</h4>

                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa fa-th-list"></i>
                                                    </span>
                                                    <span>To Address</span>
                                                    </div>

                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon"><img src="/img/workflow-element-icon/upload.png" alt="Uploads"></span>
                                                    <span>To Uploads </span>
                                                    </div>

                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon"><i class="fa fa-exchange"></i></span>
                                                    <span>Interaction Property  </span>
                                                    </div>

                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <img src="/dist/img/agent.png" width="30">
                                                    </span>
                                                    <span>Agent Picker</span>
                                                    </div>

                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa fa-circle-o-notch"></i>
                                                    </span>
                                                    <span>Case Properties</span>
                                                    </div>

                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa fa-eye"></i>
                                                    </span>
                                                    <span>Preview Display</span>
                                                    </div>

                                                    <div class="element-item" draggable="true">
                                                    <span class="element-item-icon">
                                                        <i class="fa fa-question"></i>
                                                    </span>
                                                    <span>Original Question</span>
                                                    </div>
                                                </div>
                                                <div class="tab-pane w-100" id="workflow_tab_property_42">
                                                    
                                                    <div class="row" v-if=" draggedElement == 'html'">
                                                    <div class="form-group">
                                                            <label class="col-12 control-label text-left">New Column Width</label>
                                                            <div iservice-id="Name" class="col-12"><input type="text" class="form-control"></div>
                                                            <div class="col-md-12 sub-level">If set, this item starts a new column with the specified width.</div>
                                                    </div>
                                                    <div class="form-group">
                                                            <label class="col-12 control-label text-left">Input Width Medium+</label>
                                                            <div iservice-id="Name" class="col-12"><input type="text" class="form-control"></div>
                                                            <div class="col-md-12 sub-level">Width of this control within its column (medium+ screens).</div>
                                                    </div>
                                                    <div class="form-group">
                                                            <label class="col-12 control-label text-left">Non-reactive Input Width</label>
                                                            <div iservice-id="Name" class="col-12"><input type="text" class="form-control"></div>
                                                            <div class="col-md-12 sub-level">Fixed width of this control within its column.</div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="col-12">
                                                            <label class="control-label text-left"> 
                                                                <Toggle/> Enable Panel
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <div class="col-12">
                                                            <label class="control-label text-left margin-r-5">Editor Style</label>
                                                            <a class="btn btn-xs margin-r-5 btn-default">RTE</a>
                                                            <a class="btn btn-xs margin-r-5 active btn-primary">Raw HTML</a>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="form-group">
                                                            <label class="col-12 control-label text-left">HTML</label>
                                                            <div iservice-id="Name" class="col-12"><textarea type="text" class="form-control"></textarea></div>
                                                    </div>
                                                
                                                    </div>

                                                    <div class="row" v-if=" draggedElement == 'buttons' || draggedElement == 'standardanswer'">
                                                        <div class="col-md-12">
                                                            <div class="box box-info">
                                                                <div class="box-header with-border">
                                                                    <h3 class="box-title">Button Style</h3>
                                                                    <span aria-hidden="true" class="fa pull-right   fa-angle-right expanded" v-bind:class = "segment1?'':'chevron-expand-indicator_expanded'" ng-class="{'': !segment1}"></span>
                                                                </div>
                                                                <div class="box-body">
                                                                  <div class="row">
                                                                    <div class="form-group">
                                                                        <div class="col-12">
                                                                            <label class="control-label text-left margin-r-5">Style</label>
                                                                            <div>
                                                                                <a class="btn btn-xs margin-r-5 active btn-primary">Primary</a>
                                                                                <a class="btn btn-xs margin-r-5 btn-danger">Danger</a>
                                                                                <a class="btn btn-xs margin-r-5 btn-success">Success</a>
                                                                                <a class="btn btn-xs margin-r-5 bg-aqua-active">Aqua</a>
                                                                                <a class="btn btn-xs margin-r-5 btn-link">Text Link</a>
                                                                            </div>
                                                                        </div>
                                                                
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label class="col-12 control-label text-left text-red">Label</label>
                                                                             <div class="col-12">
                                                                                <input class="is-invalid form-control" type="text">
                                                                            </div>
                                                                        <div class="col-12">
                                                                            <div class="validator-message">
                                                                                <div class="is-invalid"></div>
                                                                                <div class="invalid-feedback"><span>Must specify button Label.</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <div class="col-12">
                                                                            <label class="control-label text-left"> 
                                                                                <Toggle/> <span>Suppress Success Message</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-group">
                                                                            <label class="col-12 control-label text-left">Font Awesome Icon Class<span>(<i class="fa fa-anchor"></i> anchor)</span></label>
                                                                            <div iservice-id="Name" class="col-12">
                                                                                <Multiselect
                                                                                    v-model="value"
                                                                                    :closeOnSelect="false"
                                                                                    placeholder="--Select Icon--"
                                                                                    :options="{
                                                                                        Notificaiton: 'Notificaiton'
                                                                                    }"
                                                                                    />
                                                                            </div>
                                        
                                                                    </div>
                                                                    <div class="form-group">
                                                                            <label class="col-12 control-label text-left">Grey-out Property</label>
                                                                            <div class="col-md-12 input-group">
                                                                                <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                                                                <div class="input-group-append">
                                                                                    <button class="btn btn-xs btn-default ms-2"><i class="fa fa-hand-o-left"></i></button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-12 sub-level">Optional name of interaction property to check to determine if this button is rendered different.</div>  
                                                                    </div>
                                                                    <div class="form-group">
                                                                            <label class="col-12 control-label text-left">Grey-out Value</label>
                                                                            <div class="col-md-12 input-group">
                                                                                <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                                                                <div class="input-group-append">
                                                                                    <button class="btn btn-xs btn-default ms-2"><i class="fa fa-hand-o-left"></i></button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-12 sub-level">Value of property when this button is normal. If the property is NOT this value, the button will be colored grey. </div>  
                                                                    </div>

                                                                    <div class="form-group">
                                                                        <label class="col-12 control-label text-left margin-r-5">Require Mode</label>
                                                                        <div class="col-12">
                                                                            <a class="btn btn-xs margin-r-5 btn-default">All Inputs Required</a>
                                                                            <a class="btn btn-xs margin-r-5 active btn-primary">None Required</a>
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-group">
                                                                            <label class="col-12 control-label text-left margin-r-5">Add Action</label>
                                                                            <div class="col-12">
                                                                                <a class="btn btn-xs btn-default margin-r-5 mb-1" v-on:click="agentNotification = true;showAction=true;">Agent Notification</a>
                                                                                <a class="btn btn-xs btn-default margin-r-5 mb-1" v-on:click="contactProperty = true;showAction=true;">Set Customer Properties</a>
                                                                                <a class="btn btn-xs btn-default margin-r-5 mb-1" v-on:click="agentTicket = true;showAction=true;">Agent Ticket</a>
                                                                                <a class="btn btn-xs btn-default margin-r-5 mb-1" v-on:click="emailToCustomer = true;showAction=true;">Email To Customer</a>
                                                                                <a class="btn btn-xs btn-default margin-r-5 mb-1" v-on:click="emailToSubmitter = true;showAction=true;">Email To Submitter</a>
                                                                                <a class="btn btn-xs btn-default margin-r-5 mb-1" v-on:click="setInteraction = true;showAction=true;">Set Interaction Property</a>
                                                                            </div> 
                                                                       
                                                                    </div>
                                                                    <div class="form-group" v-if="showAction">
                                                                        <label class="col-12 control-label text-left margin-r-5">Action</label>
                                                                        <div class="col-12">
                                                                            <div id="agent-notification" v-if="agentNotification" class="box box-success">
                                                                                <div class="box-header with-border">
                                                                                    <h3 class="box-title">Agent Notification</h3>
                                                                                    <span class="pull-right">
                                                                                        <a class="btn btn-xs btn-danger margin-r-5"><i class="fa fa-times" aria-hidden="true"></i></a>
                                                                                        <a class="btn btn-xs btn-default margin-r-5"><i class="fa fa-arrow-down" aria-hidden="true"></i></a>
                                                                                    </span>
                                                                                </div>
                                                                                <div class="box-body">
                                                                                    <div>Send a notification to the assigned agent.</div>
                                                                                    <div class="row">
                                                                                        <div class="form-group">
                                                                                            <label class="col-12 control-label text-left text-red">Subject</label>
                                                                                            <div class="col-12"><input class="is-invalid form-control" type="text"></div>
                                                                                            <div class="col-12 ">
                                                                                                <div class="validator-message">
                                                                                                    <div class="is-invalid"></div>
                                                                                                    <div class="invalid-feedback"><span>Must specify notification subject.</span></div>
                                                                                                </div>
                                                                                                Subject of the notification. 
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="form-group">
                                                                                            <label class="col-12 control-label text-left">Notification Body Name</label>
                                                                                            <div class="col-md-12 input-group">
                                                                                                <input class="is-invalid form-control" type="text">
                                                                                                <div class="input-group-append">
                                                                                                    <button class="btn btn-xs btn-default ms-2"><i class="fa fa-hand-o-left"></i></button>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="invalid-feedback"><span>Must specify name of notification body.</span></div>
                                                                                            <div class="col-md-12 sub-level">Name of notification body sent as a notification. </div>  
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div id="contact-property" v-if="contactProperty" class="box box-success">
                                                                                <div class="box-header with-border">
                                                                                    <h3 class="box-title">Set Contact Properties</h3>
                                                                                    <span class="pull-right">
                                                                                        <a class="btn btn-xs btn-danger margin-r-5"><i class="fa fa-times" aria-hidden="true"></i></a>
                                                                                        <a class="btn btn-xs btn-default margin-r-5"><i class="fa fa-arrow-up" aria-hidden="true"></i></a>
                                                                                    </span>
                                                                                </div>
                                                                                <div class="box-body">
                                                                                    <div>All contact properites in the panel are saved to the contact.</div>
                                                                                    <div class="row">
                                                                                        <div class="form-group">
                                                                                            <label class="col-12 control-label text-left text-red">Customer Email Input</label>
                                                                                            <div class="col-12">
                                                                                                <select class="form-control is-invalid">
                                                                                                      <option>Customer Email Input</option>
                                                                                                </select>
                                                                                                <div class="invalid-feedback">
                                                                                                     <span>Must choose customer email address input.</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            
                                                                                            <div class="col-12">
                                                                                                Email address used to create or find the customer contact. 
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                            </div>

                                                           <!-- <div class="box box-info">
                                                                <div class="box-header with-border" v-on:click="toggleSegmentCollapsedState(2)">
                                                                    <h3 class="box-title">Add Actions</h3>
                                                                    <span aria-hidden="true" class="fa pull-right   fa-angle-right expanded" v-bind:class = "segment2?'':'chevron-expand-indicator_expanded'" ng-class="{'': !segment2}"></span>
                                                                </div>
                                                                <div class="box-body" v-bind:class = "segment2?'in collapse':''">
                                                                    <div class="element-item" id="standardanswer" draggable="true" @dragstart="startDrag($event, 'standardanswer')">
                                                                        <span class="element-item-icon" >
                                                                            <i class="fa fa-reply"></i>
                                                                        </span>
                                                                            <span>Standard Answer</span>
                                                                    </div>
                                                                    <div class="element-item" id="agentemailpanel" draggable="true" @dragstart="startDrag($event, 'agentemail')">
                                                                        <span class="element-item-icon">
                                                                            <i class="fa fa-envelope"></i>
                                                                        </span>
                                                                            <span>Agent Email Panel</span>
                                                                    </div>
                                                                    <div class="element-item" id="createnotepanel" draggable="true" @dragstart="startDrag($event, 'createnote')">
                                                                        <span class="element-item-icon">
                                                                            <i class="fa fa-pencil-square-o"></i>
                                                                        </span>
                                                                            <span>Create Note Panel</span>
                                                                    </div>

                                                                    <div class="element-item" id="setdraft" draggable="true"  @dragstart="startDrag($event, 'savedraft')">
                                                                        <span class="element-item-icon">
                                                                            <i class="fa fa-save"></i>
                                                                        </span>
                                                                            <span>Save Draft</span>
                                                                    </div>

                                                                    <div class="element-item" id="sendleaveopen" draggable="true">
                                                                        <span class="element-item-icon">
                                                                            <i class="fa fa-circle-o"></i>
                                                                        </span>
                                                                            <span>Send Leave Open</span>
                                                                    </div>

                                                                    <div class="element-item" id="sendresolve" draggable="true">
                                                                        <span class="element-item-icon">
                                                                            <i class="fa icon-c icon-resolve-note"></i>
                                                                        </span>
                                                                            <span>Send Resolve</span>
                                                                    </div>

                                                                    <div class="element-item" id="saveresolve" draggable="true">
                                                                        <span class="element-item-icon">
                                                                            <i class="fa fa-save"></i>
                                                                        </span>
                                                                            <span>Save Resolve</span>
                                                                    </div>

                                                                    <div class="element-item" id="saveprivatenote" draggable="true">
                                                                        <span class="element-item-icon">
                                                                            <i class="fa icon-c icon-private-note"></i>
                                                                        </span>
                                                                            <span>Save Private Note</span>
                                                                    </div>

                                                                    <div class="element-item" id="changetopic" draggable="true">
                                                                        <span class="element-item-icon">
                                                                            <i class="fa fa-arrows-alt"></i>
                                                                        </span>
                                                                            <span>Change Topic</span>
                                                                    </div>

                                                                    <div class="element-item" id="assigntoagent" draggable="true">
                                                                        <span class="element-item-icon">
                                                                            <i class="fa icon-c icon-forward-to-agent"></i>
                                                                        </span>
                                                                            <span>Assign To Agent</span>
                                                                    </div>

                                                                    <div class="element-item" id="unassign" draggable="true">
                                                                        <span class="element-item-icon">
                                                                            <i class="fa icon-c icon-unassign-question"></i>
                                                                        </span>
                                                                            <span>Unassign</span>
                                                                    </div>

                                                                    <div class="element-item" id="sendnotification" draggable="true">
                                                                        <span class="element-item-icon">
                                                                            <i class="fa fa-bell-o"></i>
                                                                        </span>
                                                                            <span>Send Notification</span>
                                                                    </div>

                                                                    <div class="element-item" id="setproperty" draggable="true">
                                                                        <span class="element-item-icon">
                                                                            <i class="fa fa-exchange"></i>
                                                                        </span>
                                                                            <span>Set Property</span>
                                                                    </div>

                                                                    <div class="element-item" id="replacetag" draggable="true">
                                                                        <span class="element-item-icon">
                                                                            <i class="fa fa-fw fa-tag"></i>
                                                                        </span>
                                                                            <span>Replace Tag</span>
                                                                    </div>

                                                                    <div class="element-item" id="agentsignature" draggable="true">
                                                                        <span class="element-item-icon">
                                                                            <i class="fa fa-fw fa-pencil-square"></i>
                                                                        </span>
                                                                            <span>Agent Signature</span>
                                                                    </div>
                                                                </div>
                                                            </div>-->
                                                        </div>
                                                                                                    
                                                    </div>

                                                    <div class="row" v-if=" draggedElement == 'tabs'">

                                                        <div class="nav-tabs-custom">
                                                            <ul class="nav nav-tabs">
                                                                    <li class="nav-item"><a id="tab-element" class="nav-link active" data-bs-toggle="tab" data-bs-target="#workflow_tab_property_43" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-pencil"></i> <span>Content</span></a> </li>
                                                                    <li class="nav-item"><a id="tab-settings" class="nav-link" data-bs-toggle="tab" data-bs-target="#workflow_tab_property_44" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-paint-brush"></i> <span>Style </span></a> </li>
                                                            </ul>
                                                            <div class="tab-content">
                                                                
                                                                <div class="w-100 tab-pane active" id="workflow_tab_property_43">
                                                                   <div class="row">
                                                                        <div class="form-group">
                                                                            <label class="col-12 control-label text-left">Tab Items</label>
                                                                            <div class="col-md-12">
                                                                                <div class="tabs-settings mb-2">
                                                                                    <div class="input-group">
                                                                                        <button type="button" class="form-control" placeholder="Tab # 1" v-on:click="tab1Detail1 = !tab1Detail1">Tab sample</button>
                                                                                        <button class="btn btn-danger" type="button" id="button-addon2"><i class="fa fa-close"></i></button>
                                                                                    </div>
                                                                                    <div v-if="tab1Detail1"   class="tab-settings-option p-2">
                                                                                        <div class="row">
                                                                                            <div class="col-12">
                                                                                                <div class="form-group">
                                                                                                    <label class="control-label text-left text-red">Tab Label</label>
                                                                                                    <div class="" style="position: relative;">
                                                                                                        <input class="is-invalid form-control" type="text" id="Workflow_Details_Panel10_Group0_Input0_Tab0_Label">
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <div class="validator-message">
                                                                                                            <div class="is-invalid"></div>
                                                                                                            <div class="invalid-feedback"><span>Must set Tab Label.</span>
                                                                                                        </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="form-group">
                                                                                                    <label class="control-label text-left text-red">Font Awesome Icon Class ( None )</label>
                                                                                                    <div class="" style="position: relative;">
                                                                                                    <select class="form-control">
                                                                                                        <option>--select icon</option>
                                                                                                    </select>
                                                                                                    </div>
                                                                                                
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                                <div class="tabs-settings mb-2" v-if="addTab">
                                                                                    <div class="input-group">
                                                                                        <button type="button" class="form-control" placeholder="Tab # 1" v-on:click="tab1Detail2 = !tab1Detail2">Tab sample</button>
                                                                                        <button class="btn btn-danger" type="button" id="button-addon2" v-on:click="addTab = false;"><i class="fa fa-close"></i></button>
                                                                                    </div>
                                                                                    <div v-if="tab1Detail2"   class="tab-settings-option p-2">
                                                                                        <div class="form-group">
                                                                                            <label class="control-label text-left text-red">Tab Label</label>
                                                                                            <div class="" style="position: relative;">
                                                                                                <input class="is-invalid form-control" type="text" id="Workflow_Details_Panel10_Group0_Input0_Tab0_Label">
                                                                                            </div>
                                                                                            <div>
                                                                                                <div class="validator-message">
                                                                                                    <div class="is-invalid"></div>
                                                                                                    <div class="invalid-feedback"><span>Must set Tab Label.</span>
                                                                                                </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="form-group">
                                                                                            <label class="control-label text-left text-red">Font Awesome Icon Class ( None )</label>
                                                                                            <div class="" style="position: relative;">
                                                                                            <select class="form-control">
                                                                                                <option>--select icon</option>
                                                                                            </select>
                                                                                            </div>
                                                                                        
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>      
                                                                        </div>
                                                                        <div class="col-12">
                                                                            <button class="btn btn-sm btn-primary" type="button"  v-on:click="addTab = true">
                                                                                <i class="fa fa-plus"></i>
                                                                                Add Tab
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="w-100 tab-pane" id="workflow_tab_property_44">
                                                                  <div class="row"> 
                                                                    <div class="form-group">
                                                                        <label class="col-12 control-label text-left">New Column Width</label>
                                                                        <div iservice-id="Name" class="col-12"><input type="text" class="form-control"></div>
                                                                        <div class="col-md-12 sub-level">If set, this item starts a new column with the specified width.</div>
                                                                    </div>
                                                                    <div class="form-group">
                                                                            <label class="col-12 control-label text-left">Input Width Medium+</label>
                                                                            <div iservice-id="Name" class="col-12"><input type="text" class="form-control"></div>
                                                                            <div class="col-md-12 sub-level">Width of this control within its column (medium+ screens).</div>
                                                                    </div>
                                                                  </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                       
                                                       
                                                    </div>

                                                </div>
                                             </div>
                                        </div>
                                    </div>
                            </div>
                          
    
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="box box-bg-color box-primary">
                                <div class="box-body in collapse show">
                                    <div class="box box-info with-border">
                                       <!-- <div class="box-header">
                                          <button class="btn btn-sm btn-primary pull-right">Preview Form</button>
                                        </div> --> 
                                        <div class="box-body table-responsive" style="padding: 15px;">
                                            <div class="row">
                                            <!--v-if-->
                                            <div class="col-md-12">
                                            <div>
                                                <div class="row">
                                                    
                                                    <div class="col-md-12" id="Workflow_Details_Panel0_Group0">
                                                        <div class="row">
                                                        
                                                            <div class="col-md-12">
                                                                <div class="row">
                                                                    <div class="mbottom-15 col-md-12" style="position: relative;" id="Workflow_Details_Panel0_Group0_Input2">
                                                                        <!---->
                                                                        <div v-if="dropElement2" class="drop-area"  @drop="onDrop($event, 2); isDragging2 = false" @dragover = "isDragging2 = true"  @dragleave = "isDragging2 = false"   @dragenter =" " v-bind:class = "isDragging2?'hover':''"  @dragover.prevent  @dragenter.prevent v-on:keypress="keyHandler()">
                                                                            <div class="row">
                                                                                <div class="col-md-12" id="Workflow_Details_Panel0_Group0_Input2_droparea">
                                                                                    <div class="text-center"> Drop element here to add</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>  
                                                                        <div class="form-horizontal" style="border: 1px dashed rgb(221, 221, 255);">
                                                                            <div class="row" >
                                                                            <label class="control-label text-left col-3">number with decimal</label>
                                                                            <div class="col-9"><input type="text" class="form-control"></div>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                        <div style="position: absolute; top: -14px; left: 5px; z-index: 1000;" v-bind:class = "(showElements)?'active':''">
                                                                            <div  v-if = "!dropElement2" class="panel-input-gap" v-on:click="showElements = true; dropElement2 = true;">
                                                                            <i class="fa fa-long-arrow-right"></i>&nbsp; <!---->
                                                                            </div>
                                                                            <div  v-if = "dropElement2" class="panel-input-gap close-droparea" v-on:click="showElements = false; dropElement2 = false;">
                                                                            <i class="fa fa-close"></i>&nbsp; <!---->
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </div>

                                                                
                                                                
                                                                    <div class="mbottom-15 col-md-12" style="position: relative;" id="Workflow_Details_Panel0_Group0_Input1">
                                                                        <div style="position: absolute; top: -14px; left: 5px; z-index: 1000;">
                                                                            <div v-if = "!dropElement1" class="panel-input-gap" v-on:click="showElements = true; dropElement1 = true;">
                                                                                <i class="fa fa-long-arrow-right"></i>&nbsp; 
                                                                            </div>
                                                                            <div v-if = "dropElement1" class="panel-input-gap close-droparea" v-on:click="showElements = false; dropElement1 = false;">
                                                                                <i class="fa fa-close"></i>&nbsp; 
                                                                            </div>
                                                                        </div>
                                                                        <div v-if="dropElement1" class="drop-area"  @drop="onDrop($event, 1); isDragging = false" @dragover = "isDragging = true"  @dragleave = "isDragging = false"   @dragenter =" " v-bind:class = "isDragging?'hover':''"  @dragover.prevent  @dragenter.prevent v-on:keypress="keyHandler()">
                                                                            <div class="row">
                                                                                <div class="col-md-12" id="Workflow_Details_Panel0_Group0_Input1_droparea">
                                                                                    <div class="text-center"> Drop element here to add</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>  
                                                                        
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>

    </div>
    <div class="row" v-if="showDetails.deleteAlert">
        <div class="col-md-12" iservice-id-prefix="'Delete'">
            <div class="box box-danger" iservice-id="'Delete'">
                <div class="box-header with-border">
                    <i class="fa fa-warning"></i>
                    <h3 class="box-title text-right">Delete Panel</h3>
                </div>
                <div class="box-body">
                    <section class="box-content box-bg-color">
                    <div class="row">
                        <div class="form-horizontal">
                            <div class="col-md-12">
                                <p>All panel values will be deleted.</p>
                                <p class="text-danger">WARNING: Deletion is immediate and irrevocable.</p>
                            </div>
                        </div>
                        <div class="form-horizontal">
                            <div class="col-md-12"><button iservice-id="Confirm" class="btn btn-danger btn-sm">Confirm Deletion</button><button iservice-id="Cancel" class="btn btn-link" v-on:click="cancel()">Cancel</button></div>
                        </div>
                    </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
  
   
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Toggle from '@vueform/toggle'
import InboxMessageDetail from '../components/InboxMessageDetail.vue';
import iServiceSwitch from '../components/iServiceSwitch.vue';
import NewContactPropertyForm from '../components/NewContactPropertyForm.vue';
import ContactDetail from '../components/ContactDetail.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'Panel Porperty Settings',
   components: {
    InboxMessageDetail,
    iServiceSwitch,
    Multiselect,
    NewContactPropertyForm,
    Toggle,
    Multiselect,
    ContactDetail,
   
  }
  ,
    data() {
     return {
        showElements : false,
        dropElement1 : false,
        dropElement2 : false,
        dropElement3 : false,
        editrow1 : false,
        topiceditrow1 : false,
        draggedElement : '',
        draggedsection : '',
        segment1 : false,
        displayformType : '',
        isDragging : false,
        paneleditrow1 :false,
        segment1:true,
        segment2:false,
        segment3:false,
        agentNotification:false,
        contactProperty:false,
        agentTicket:false,
        emailToCustomer:false,
        emailToSubmitter:false,
        setInteraction:false,
        showAction:false,
        tab1Detail1:false,
        tab1Detail2:false,
        addTab:false
     }
    },
  methods:{
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    },
    startDrag(evt, item) {
      this.draggedElement = item;
    },
    startDrag(evt, item,draggedsection) {
      this.draggedElement = item;
      this.draggedsection = draggedsection;
    },
    onDrop(evt, item) {
      var element_id = this.draggedElement + '-element';
      var dropAreaHTMl = document.getElementById(element_id).innerHTML;
      if(this.draggedElement  == 'standardanswer'){
       
        var currentHtml = document.getElementById("Workflow_Details_Panel0_Group0_Input"+ item +"_droparea").innerHTML;

        document.getElementById("Workflow_Details_Panel0_Group0_Input"+ item +"_droparea").innerHTML = currentHtml + dropAreaHTMl  ;

      }else{
        document.getElementById("Workflow_Details_Panel0_Group0_Input"+ item +"_droparea").innerHTML = dropAreaHTMl;
      }
      document.getElementById("tab-settings").click();
    },   
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    } 
  },
  setup(){      
       
       var showDetails = reactive({showNew:false,deleteAlert:false, showContactDetails:false,currentRow:''});
       var showNew =   reactive(false);
       var deleteAlert =  reactive(false);
       var show = reactive(false);
       var currentRow = '';

       function showNewAlert(formtype){
       
         showDetails.displayformType = formtype;
         showDetails.showNew = true; 
         showDetails.deleteAlert = false;  
         showDetails.showContactDetails = false;
         showDetails.active_el = '';
       }
       function showDeleteAlert(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = true;  
       }
       function showMessageDetails(val,row){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showContactDetails=true;
         showDetails.active_el = row;
       }
       function cancel(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showContactDetails=false;
       }

       return{cancel,showNew,deleteAlert,showDetails,showNewAlert,showDeleteAlert,showMessageDetails};
  }

}
</script>
